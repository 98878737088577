<template>
  <div class="range" v-show="!isHidden" :style="CSS" :class="CSSClasses">
   <div v-show="rangeSettings.showValue" class="value_range">{{rangeValue}}</div>
    <el-slider
      v-model="rangeSettings.initialValue"
      :height="rangeSettings.height"
      :show-tooltip="rangeSettings.tooltip"
      :max="rangeSettings.max"
      :min="rangeSettings.min"
      :step="rangeSettings.step"
      :vertical="rangeSettings.vertical"
      :show-stops="rangeSettings.showStops"
      :range="rangeSettings.range"
      :format-tooltip="formatTooltip"
      :disabled="_isReadonly"
      @change="onChangeValueRange"
    ></el-slider>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
export default {
  name: 'a-range',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [VisibleMixin, mixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },
    Range: {
      type: Object,
      editor: 'Range',
      default: () => {
        return { }
      }
    }
  },
  data () {
    return {
      rangeSettings: {
        initialValue: 0,
        max: 100,
        min: 0,
        step: 10,
        height: '200px',
        tooltip: false,
        showStops: false,
        vertical: false,
        showValue: false,
        initFilter: true,
        range: false,
        formatTooltip: {
          formatValue: 1,
          symbol: ''
        }
      }

    }
  },
  computed: {
    rangeValue () {
      let value = this.rangeSettings.initialValue
      let formatValue = this.rangeSettings.formatTooltip.formatValue

      if (this.Range.formatTooltip?.symbol) {
        if (Array.isArray(value)) {
          value = value.map(item => `${item / formatValue}${this.Range.formatTooltip.symbol}`).join(' - ')
          return value
        }

        return `${value / formatValue}${this.Range.formatTooltip.symbol}`
      }

      if (Array.isArray(value)) {
        value = value.map(item => item / formatValue).join(' - ')
      }
      return value / formatValue
    }
  },
  methods: {
    onChangeValueRange (val) {
      // console.log('onChangeValueRange', val)
      this.$emit('input', val)
    },
    formatTooltip (val) {
      if (this.Range.tooltip) {
        let valueRange = val / this.Range.formatTooltip.formatValue
        return `${valueRange} ${this.Range.formatTooltip.symbol}`
      }
      return val
    }
  },
  beforeMount () {
    for (const property in this.Range) {
      if (this.rangeSettings[property] !== undefined) {
        this.$set(this.rangeSettings, property, this.Range[property])
      } else {
        console.warn('Не найдено свойство', property)
      }
    }
    if (this.rangeSettings.range) {
      this.rangeSettings.initialValue = this.Range.multiValue.split(',').map(Number)
    }
    if (this.rangeSettings.initFilter) {
      this.$emit('input', this.rangeSettings.initialValue)
    }
    // console.log(this.rangeSettings)
  }
}
</script>

<style scoped>
.range {
  padding: 20px;
}
</style>
